"use strict";

require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.iterator.constructor.js");
require("core-js/modules/es.iterator.filter.js");
require("core-js/modules/es.number.constructor.js");
require("core-js/modules/es.object.to-string.js");
exports.__esModule = true;
exports.default = void 0;
var _utils = require("vant/lib/utils");
var _relation = require("vant/lib/mixins/relation");
var _constant = require("vant/lib/utils/constant");
var _createNamespace = (0, _utils.createNamespace)('telezx-collapse'),
  createComponent = _createNamespace[0],
  bem = _createNamespace[1];
var _default = createComponent({
  mixins: [(0, _relation.ParentMixin)('vanTelezxCollapse')],
  props: {
    accordion: Boolean,
    value: [String, Number, Array],
    border: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    switch: function _switch(name, expanded) {
      if (!this.accordion) {
        name = expanded ? this.value.concat(name) : this.value.filter(function (activeName) {
          return activeName !== name;
        });
      }
      this.$emit('change', name);
      this.$emit('input', name);
    }
  },
  render: function render() {
    var _ref;
    var h = arguments[0];
    return h("div", {
      "class": [bem(), (_ref = {}, _ref[_constant.BORDER_TOP_BOTTOM] = this.border, _ref)]
    }, [this.slots()]);
  }
});
exports.default = _default;